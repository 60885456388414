
import { arrayOf, nullable, object, oneOfType, string } from 'vue-types';
export default {
  inheritAttrs: false,
  props: {
    heading: oneOfType([string(), nullable()]),
    body: oneOfType([string(), nullable()]),
    backgroundImage: arrayOf(object()),
    buttonLink: arrayOf(object()),
    imageTiles: arrayOf(object()),
  },
};
